import React from 'react';
import { useParams } from 'react-router-dom';
import SpinningLogo from '../SpinningLogo';



const ProjectDetails = () => {


  const { projectId } = useParams();

  switch (projectId) {

    case "ascii_snake": {
      return (
        <div>
            <h1 class="main-title">ascii-snake 🐍</h1>
            <div class="col-12">
              <h2>A terminal based snake game that utilizes the ncurses API.</h2>
              <h3>(This was my final project in <a class="inline-link link" href="https://pll.harvard.edu/course/cs50-introduction-computer-science">CS50</a>)</h3>
              <a href="https://github.com/5mit/ascii-snake"><h3 class="btn btn-secondary btn-small m-2" >Check it out on GitHub</h3></a>
              <div class="demo-video">
                <img src="/images/projects/ascii-snake/snake-demo.gif" alt="demo"/>
              </div>
              <pre class="text-left">
              I’ve always wanted to create a video game but I never knew how. That is until I took CS50. That course  was my introduction into programming, and once I had made it to the final project, I felt I had the skills necessary to program the logic behind a game.
              </pre>
              <pre class="text-left">
              I decided to make a command line game in C. At this time C was the language I knew best, and coming from a Linux background, I have a soft spot for command line utilities. However, using scanf and printf to make a game seemed a bit limited. That’s when I discovered ncurses, which allowed me to print characters in a grid like fashion. Given all of these tools I felt that making snake would be a good choice for my first game. 
              </pre>
              <pre class="text-left">
              When creating the game, I decided to implement the snake as a linked list of nodes. I had recently learned this data structure and to me this seemed like an intuitive use for one. I guess I can still say it’s intuitive, but it’s anything but efficient. Shortly after making the game a friend pointed out to me that all I really needed to keep track of was the head and the tail of the snake. Oh well, at least I got some thorough practice implementing and using a linked list!
              </pre>
              <div class="demo-video">
                <img src="/images/projects/ascii-snake/snake-demo2.gif" alt="demo2" />
                <p>chasing that $</p>
              </div>
            </div>
            <p class="project-date">Last updated: January 18th, 2024</p>
        </div>
      );
    }
    case "gigachadpizzacutter": {
      return (
        <div>
            <h1 class="main-title">GigaChad Pizza Cutter 🍕</h1>
            <div class="col-12 content">
            <h3>The GIGACHAD way to cut a pizza!</h3>
              <a href="https://github.com/5mit/gigachadpizzacutter.c"><h3 class="btn btn-secondary btn-small m-2" >Check it out on GitHub</h3></a>
                <pre class="text-left"> 
                 How do you cut a pizza? It's probably not something you give much thought to. Cut one line down the middle, then maybe another that is perpendicular to the last. Now you have four equal slices. If you wanted more you could split each of those slices into two and have eight equal slices.
                </pre>
                <img src="/images/projects/gigachadpizzacutter/betacuts.jpg" alt="betacuts" />
                <pre class="text-left"> 
                So really, slicing your pizza into equal slices is a trivial task. I used to think this method was peak pizza cutting. That is until a video by Shahjahon A.A. Saidmurodov popped up in my YouTube recommendations. I strongly recommend you check it out—especially if you want a better idea of how this program works. 
                </pre>
                <iframe class="youtube" src="https://www.youtube-nocookie.com/embed/IeCxjcMyY-M?si=TB7O0PA9zK3bQYK0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <pre class="text-left"> 
                Basically, Shahjahon redpills us and says our conventional pizza cutting method is no good. To cut pizza like a chad we can’t have intersecting cuts: we need parallel lines. Cutting your pizza with parallel lines is simply enough, but what if you still wanted each slice to be equal? To be clear, by equal we mean equal area. This is an issue Shahjahon solves by modeling the situation around a quarter circle. He takes the equation of the quarter circle and integrates to find the x positions in which cuts can be made such that all slices will have the same area. 
                </pre>
                <pre class="text-left"> 
                The YouTube algorithm really served up a gem with this one. I really enjoyed seeing such a ridiculous problem have such interesting and satisfying math behind it. Yet, Shahjahon only demonstrated a single case in which a pizza with a 7 unit radius pizza is cut into 3 slices. What about an n unit radius pizza cut into x slices? Doesn’t that sound delicious? So I sought out to create a generalized equation for this problem. 
                </pre>
                <pre class="text-left"> 
                I managed to derive a generalized definite integral equation and in solving it I got as far as this equation:
                </pre>
                <img src="/images/projects/gigachadpizzacutter/equation.png" alt="equation"/>
                <pre class="text-left"> 
                This was as far as I managed to simplify it, so like Shahjahon, to solve for x I’d have to approximate it. Shahjahon used desmos, which would work great for a one off calculation, but I want to make a general function I can input my pizza radius and number of slices into and be given when I should cut. Therefore, writing a program to do so would be perfect.
                </pre>
                <pre class="text-left"> 
                I decided to write it in C since that’s what I am most familiar with and I thought it would be fun to have as a command-line utility. The program first  asks the user for the diameter of their pizza and the number of slices they would like.I chose diameter rather than radius because in practice it’s faster to measure. 
                </pre>  
                <pre class="text-left"> 
                Once given the input the program branches into different logic depending on if the number of slices is even or odd. We need to make this distinction because in a case with even slices we have a cut down the middle of the pizza. The calculations are modeled around a quarter circle so this changes how the first slice is calculated. If the slices are odd, the area of this first piece will be split across the middle of the pizza, meaning half of this area will not present when calculating from the perspective of a quarter of the pizza.
                </pre>
                <pre class="text-left"> 
                From here, the program initializes the rest of the array that stores the area of pizza for each piece we cut. After that is done, it uses Newton's method to approximate a solution to that equation I listed above. This approximation finds the x position for each cut within about a ten-thousandth of a percent (one millionth of the unit). Hopefully that’s precise enough for our pizza cutting needs. 
                </pre>
                <pre class="text-left"> 
                Once the program gets the positions to cut it outputs them to screen. It only actually calculated half the cuts since this equation models a quarter circle, but the cuts are symmetrical across the center so they are simply printed twice. The middle line is considered x=0, so the cuts are printed in reverse order as negative values, then they are printed in their normal order as positive values. 
                </pre>
                <h1 class="main-title">Demo</h1>
                <img src="/images/projects/gigachadpizzacutter/demo.png" alt="demo"/>
                <h1 class="main-title">W!</h1>
                <h2 class="content-header">I'll never eat pizza any other way again.</h2>
                <p class="subtle">(Unfortunately the Dominos employee did not know what the hell I was talking about, so we had to cut the pizza ourselves)</p>
                <img src="/images/projects/gigachadpizzacutter/w.jpg" alt="result" />
                <h2 class="content-header"> 
                 Here's the calculation for it:
                </h2>
                <img src="/images/projects/gigachadpizzacutter/dominos-calc.png" alt="result-calc" />
            </div>
            <p class="project-date">Last updated: March 10th, 2024</p>
        </div>
      );
    }
    case "toughbook_restore": {
      return (
        <div>
            <h1 class="main-title">Panasonic TOUGHBOOK Restoration</h1>
            <div class="col-12">
            <pre class="text-left"> 
            My friend managed to find a Panasonic Toughbook cf-19 MK 1 at a yard sale for a dollar a few years ago. Yeah... a <b>dollar</b>. You definitely can’t go wrong with a deal like that, but this thing isn’t exactly pristine. Take a look for yourself: 
            </pre>
            <img class="project-inline" src="/images/projects/toughbook-restore/closed.jpg" alt="closed" />
            <pre class="text-left"> 
            Okay, some sticker gunk and dirt. Big whoop. But what are those cables? They’re USB 1.0 extension cables that were left in by the previous owner. Why did I leave them in? Well... Here's the rear USB port:
            </pre>
            <img class="project-inline" src="/images/projects/toughbook-restore/rear-usb.jpg" alt="rear-1" />
            <img class="project-inline" src="/images/projects/toughbook-restore/rear-usb2.jpg" alt="rear-2" />
            <pre class="text-left"> 
            And here's the left USB port:
            </pre>
            <img class="project-inline" src="/images/projects/toughbook-restore/left-usb.jpg" alt="left" />
            </div>
            <pre class="text-left"> 
            I'm starting to think this was less of a yard sale and more like a post-crucifixion liquidation sale. Somebody mutilated and tortured this poor thing. They not only hot glued these cables in, they ripped off the plastic covers that go over the ports when they aren’t in use. I’d love to hear the reasoning behind why this happened. Did the cables really unplug easily enough to warrant this? From what I gathered, this machine was used in a mine somewhere. I don't know about you, but that sounds like a cover up for a laptop torture ring to me. 
            </pre>
            <pre class="text-left"> 
            Yet, regardless of the torture this thing faced in the past, my hope is to clean it up and and give it new life. The raw specs are not very good by today’s standards—this one came with a Core Duo and 2GB of DDR2 ram—but it boasts some really cool features. You can take a look at an overview <a href="https://www.mruggedmobile.com/images/panasonic/19/mrugged_cf19_mk1.pdf"><u class="link">here</u></a>. Foremost of these cool features is the ruggedness. You could throw this thing through a wall and it won’t even flinch. As you can imagine it’s also rather hefty as a result. It would double as a great fish bat. This thing is also kitted with bluetooth 2.0 and some models even have GPS receivers. The display also swivels 180 degrees and folds back, turning the device into a tablet complete with a Nintendo DS-type stylus that is housed in the bottom of the screen. 
            </pre>
            <pre class="text-left"> 
            Once I clean this thing up, I plan to install a lightweight linux distro and window manager. After that, I think it would be cool to try and get that stylus working so I can use this thing as the world’s most bulky and inefficient DS emulator. 
            </pre>
            <pre class="text-center"> 
              I’ll update this page as I go with further progress. 
            </pre>
            <p class="project-date">Last updated: March 25th, 2024</p>
        </div>
      );
    }
    default: {
      return (
        <div>
            <h1 class="main-title">Project Not Found :(</h1>
            <SpinningLogo />
        </div>
      );
    }

  }
};

export default ProjectDetails;