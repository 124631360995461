import React from 'react';

const SpinningLogo = () => {
  return (
    <div className="spinning-logo">
      {/* Your logo or content goes here */}
      <div className="logo-content"><img src="/images/logo.png" alt="logo"/></div>
    </div>
  );
};

export default SpinningLogo;