import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
  return (
    <div>
    <header>
         {/* Navigation bar */}
         <nav className="navbar navbar-expand-lg navbar-dark fixed-top custom-navbar">
                    <div className="d-flex flex-grow-1">
                        <span className="w-100 d-lg-none d-block"></span>
                        <div className="navbar-brand d-inline-block">
                            <Link class="sitename" to="/">
                                    <img src="/images/logo.png" alt="logo" className="logo align-top" />
                                    <b class="sitename">5mithub</b>
                            </Link>
                        </div>
                        <div className="w-100 text-right">
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#myNavbar">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                    </div>
                    <div className="collapse navbar-collapse flex-grow-1 text-right" id="myNavbar">
                        <ul className="navbar-nav ml-auto flex-nowrap">
                             <li className="nav-item">
                                <Link to="/projects" className="nav-link m-2">Projects</Link>
                            </li>
                            <li className="nav-item">
                                <a href="https://github.com/5mit" className="nav-link m-2" rel="noreferrer" target="_blank">
                                    <img src="/images/github-logo.png" alt="GitHub"  />
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
        </header>
    </div>
  );
};

export default Nav;