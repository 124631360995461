import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const projects = [
 
  { id: 'gigachadpizzacutter', 
        title: 'GigaChad Pizza Cutter', 
        thumbnail: '/images/projects/gigachadpizzacutter/title.png', 
        description: 'A program that redpills you on cutting pizza ' },
  { id: 'ascii_snake', 
        title: 'ascii-snake', 
        thumbnail: '/images/projects/ascii-snake/title.png', 
        description: 'A terminal based snake game that utilizes the ncurses API '},
  { id: 'toughbook_restore', 
        title: 'TOUGHBOOK Restoration', 
        thumbnail: '/images/projects/toughbook-restore/title.png', 
        description: 'Which one of you hot glue lovers did this?'},
  // Add more projects as needed
];

const ProjectsList = () => {

   useEffect(() => {
        // Set page title
        document.title = "Projects - 5mithub";
   }, []);

   return (
    <div>
      <h2 className="main-title">My Projects 🔨</h2>
      <ul className="card-list list-unstyled d-flex flex-wrap">
        {projects.map((project) => (
          <li key={project.id} className="project-list-entry card-list-entry col-md-4 col-sm-12 mb-4">
            <div className=" project-card card" >
              <img className="card-img-top" src={project.thumbnail} alt="Card image cap" />
              <div className="card-body">
                <h5 className="project-card-title card-title">{project.title}</h5>
                <p className="card-text">{project.description}</p>
                <Link class=" btn btn-primary" to={`/projects/${project.id}`}>Read More</Link>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
        };
export default ProjectsList;
