import React from 'react';
import Nav from './Nav'
import SpinningLogo from './SpinningLogo';

const NotFound = () => {
  return (
  
    <div>
      <Nav />
      <h1 class="main-title">404 - Page Not Found :(</h1>
      <SpinningLogo />
    </div>
  );
};

export default NotFound;
