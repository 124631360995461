import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';



const Home = () => {
    useEffect(() => {

        // Set page title
        document.title = "5mithub";

            // List of homepage greetings
            const textsToType = [
                "What's up?",
                "Right, what's all this then?",
                "Two outta three ain't bad.",
                "Ford... you're turning into a penguin. Stop it.",
                "What is the air-speed velocity of an unladen swallow?",
                "Karmanya wadhikaraste Ma Faleshu Kadachen.",
                "Kanuck, Tuckahoe, Congressman, Cuff, I give them the same, I receive them the same."
        
            ];
        
            const typingTextElement = document.getElementById('typing-text');
            const initialDelay = 1000; 
            const typingSpeed = 100;
        
            async function typeText(text) {
                for (let i = 0; i < text.length; i++) {
                    await new Promise(resolve => setTimeout(resolve, typingSpeed));
                    typingTextElement.textContent = typingTextElement.textContent.slice(0, -1);
                    typingTextElement.textContent += text[i];
                    typingTextElement.textContent += "_";
                }
                
            }


        
            // Randomly select a text from the array
            const randomText = textsToType[Math.floor(Math.random() * textsToType.length)];
        
            // Initialize typing after an initial delay with the randomly selected text
            setTimeout(() => typeText(randomText), initialDelay);
    }, []);


    return (
            <main>
                {/* Welcome title*/}
                <div className="typing-container">
                    <h1 id="typing-text"> _</h1>
                </div>


                {/* Carousel */}
                <div>
                    <h1 id="promo-title" className="main-title"><strong>Featured Projects 🔨</strong></h1>
                </div>
                <div id="promo" className="carousel slide" data-ride="carousel">
                    {/* Indicators */}
                    <ul className="carousel-indicators">
                        <li data-target="#promo" data-slide-to="0" className="active"></li>
                        <li data-target="#promo" data-slide-to="1"></li>
                        <li data-target="#promo" data-slide-to="2"></li>
                    </ul>

                    {/* Slideshow */}
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <Link to={`/projects/gigachadpizzacutter`}>
                                <img src="images/projects/gigachadpizzacutter/title.png" alt="gigachadpizzacutter" />
                            </Link>
                        </div>
                        <div className="carousel-item">
                                <Link to={`/projects/ascii_snake`}>
                                    <img src="images/projects/ascii-snake/title.png" alt="ascii-snake" />
                                </Link>
                        </div>
                        <div className="carousel-item">
                             <Link to={`/projects/toughbook_restore`}>
                                <img src="images/projects/toughbook-restore/title.png" alt='TOUGHBOOK Restore' />
                            </Link>
                        </div>

                    {/* Controls */}
                     {/*className="fa fa-arrow-left*/}
                     <a className="carousel-control-prev" href="#promo" data-slide="prev">
                        <span className="fa fa-arrow-left"></span>
                    </a>
                    <a className="carousel-control-next" href="#promo" data-slide="next">
                        <span className="fa fa-arrow-right" ></span>
                    </a>
                        
                    </div>

                </div>

                <div className="linux-config">
                    <div class="linux-config-title">
                        <h1 className="main-title"><strong> My Linux Config </strong></h1>
                        <img src="images/tux.png" alt="tux"/>
                    </div>
                    <div className="linux-config-entries-container">
                        <ul className="linux-config-entries">
                            <li>
                                <a href="https://github.com/5mit/dwm" className="btn btn-secondary btn-small m-2">
                                    <h3 id="about-promo-text">dwm</h3>
                                </a>
                            </li>
                            <li>
                                <a href="https://github.com/5mit/dmenu" className="btn btn-secondary btn-small m-2">
                                    <h3 id="about-promo-text">dmenu</h3>
                                </a>
                            </li>
                            <li>
                                <a href="https://github.com/5mit/slstatus" className="btn btn-secondary btn-small m-2">
                                    <h3 id="about-promo-text">slstatus</h3>
                                </a>
                            </li>
                            <li>
                                <a href="https://github.com/5mit/st" className="btn btn-secondary m-2">
                                    <h3 id="about-promo-text">st</h3>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            <h1 className="main-title">Goated Videos</h1>



            <ul className="card-list list-unstyled d-flex flex-wrap">

             <li className="youtube-container project-list-entry card-list-entry col-md-4 col-sm-12 mb-4">
            <a class="link" href="https://www.youtube-nocookie.com/embed/9UwOdFzPTH4?si=q2KNskG84H0WQg9v"  target="_blank" rel="noopener noreferrer">
            <div className="project-card card h-100 d-flex flex-column">
                <h5 className="mb-0 project-card-title card-title link">A Guide to Eliminating Procrastination</h5>
                <img
                        src="https://img.youtube.com/vi/9UwOdFzPTH4/mqdefault.jpg" 
                        alt="A Guide to Eliminating Procrastination"
                        className="youtube-thumbnail"
                 />
            </div>
            </a>
            </li>



            <li className="youtube-container project-list-entry card-list-entry col-md-4 col-sm-12 mb-4">
            <a class="link" href="https://www.youtube-nocookie.com/embed/Nq5C5qL1nsc?si=IKDH9IJdyiLfULfk"  target="_blank" rel="noopener noreferrer">
            <div className="project-card card h-100 d-flex flex-column">
                <h5 className="mb-0 project-card-title card-title link">why is Sisyphus happy?</h5>
                <img
                        src="https://img.youtube.com/vi/Nq5C5qL1nsc/mqdefault.jpg"
                        alt="why is Sisyphus happy?"
                        className="youtube-thumbnail"
                />
            </div>
            </a>
            </li>


           <li className="youtube-container project-list-entry card-list-entry col-md-4 col-sm-12 mb-4">
            <a class="link" href="https://www.youtube-nocookie.com/embed/-HwwY4czx_E?si=c4qrKAKh40LK1WBv"  target="_blank" rel="noopener noreferrer">
            <div className="project-card card h-100 d-flex flex-column">
                <h5 className="mb-0 project-card-title card-title link">Factorials, Harmonic Numbers, and Trig</h5>
                <img
                        src="https://img.youtube.com/vi/-HwwY4czx_E/mqdefault.jpg"
                        alt="Factorials, Harmonic Numbers, and Trig"
                        className="youtube-thumbnail"
                />
            </div>
            </a>
            </li>

           <li className="youtube-container project-list-entry card-list-entry col-md-4 col-sm-12 mb-4">
            <a class="link" href="https://www.youtube-nocookie.com/embed/86xWVb4XIyE?si=KTJjU54RsTFxgZQv"  target="_blank" rel="noopener noreferrer">
            <div className="project-card card h-100 d-flex flex-column">
                <h5 className="mb-0 project-card-title card-title link">Bjarne Stroustrup - The Essence of C++</h5>
                <img
                    src="https://img.youtube.com/vi/86xWVb4XIyE/mqdefault.jpg"
                    alt="Bjarne Stroustrup - The Essence of C++"
                    className="youtube-thumbnail"
                />
            </div>
            </a>
            </li>


            <li className="youtube-container project-list-entry card-list-entry col-md-4 col-sm-12 mb-4">
            <a class="link" href="https://www.youtube-nocookie.com/embed/I6xwMIUPHss?si=YRYAgyOCaFMf-Isb"  target="_blank" rel="noopener noreferrer">
            <div className="project-card card h-100 d-flex flex-column">
                <h5 className="mb-0 project-card-title card-title link">The Company that Broke Canada</h5>
                <img
                        src="https://img.youtube.com/vi/I6xwMIUPHss/mqdefault.jpg"
                        alt="The Company that Broke Canada"
                        className="youtube-thumbnail"
                />
            </div>
            </a>
            </li>

            <li className="youtube-container project-list-entry card-list-entry col-md-4 col-sm-12 mb-4">
            <a class="link" href="https://www.youtube-nocookie.com/embed/pPXQGnsrnx8?si=ZsZtVjobeG6GVFLu"  target="_blank" rel="noopener noreferrer">
            <div className="project-card card h-100 d-flex flex-column">
                <h5 className="mb-0 project-card-title card-title link">Phone Phreaking: Innovation at 2600Hz</h5>
                <img
                    src="https://img.youtube.com/vi/pPXQGnsrnx8/mqdefault.jpg"
                    alt="Phone Phreaking: Innovation at 2600Hz"
                    className="youtube-thumbnail"
                />
            </div>
            </a>
            </li>

            <li className="youtube-container project-list-entry card-list-entry col-md-4 col-sm-12 mb-4">
            <a class="link" href="https://www.youtube-nocookie.com/embed/yGhfUcPjXuE?si=drwI9YM_2DnuNn2x" target="_blank" rel="noopener noreferrer">
            <div className="project-card card h-100 d-flex flex-column">
                <h5 className="mb-0 project-card-title card-title link">Dear Game Developers, Stop Messing This Up!</h5>
                <img
                    src="https://img.youtube.com/vi/yGhfUcPjXuE/mqdefault.jpg"
                    alt="Dear Game Developers, Stop Messing This Up!"
                    className="youtube-thumbnail"
                />
            </div>
            </a>
            </li>

            <li className="youtube-container project-list-entry card-list-entry col-md-4 col-sm-12 mb-4">
            <a class="link" href="https://www.youtube-nocookie.com/embed/p_di4Zn4wz4?si=AxGnrwbI5j0MzkUR" target="_blank" rel="noopener noreferrer">
            <div className="project-card card h-100 d-flex flex-column">
                <h5 className="mb-0 project-card-title card-title link">Differential equations, a tourist's guide | DE1</h5>
                <img
                    src="https://img.youtube.com/vi/p_di4Zn4wz4/mqdefault.jpg"
                    alt="Differential equations, a tourist's guide | DE1"
                    className="youtube-thumbnail"
                />
            </div>
            </a>
            </li>

</ul>








         </main>
    );
};

export default Home;