
import React from 'react';
import Nav from './Nav'

const Layout = ({ children }) => {
  return (
    <div>
      
      <Nav />
      
      {children}
      
      
        <footer>
                <img class="footer-logo" src="/images/logo.png" alt="logo" />
                <p>Send Me an Email!</p>
                <address>5mit@proton.me</address>
        </footer>
    </div>
  );
};

export default Layout;